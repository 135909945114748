import React from "react";
import Drawer from '@mui/material/Drawer';
import {
    Box,
    ClickAwayListener,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import TableChartIcon from '@mui/icons-material/TableChart';
import HomeIcon from '@mui/icons-material/Home';
import {User} from "../../interfaces/User/User";

interface Props {
    open : boolean
    onClose : (path? : string) => void
    user : User
}

const Navigation : React.FC<Props> = (props: Props) => {

    const renderListItem = (text: string, path : string, icon : JSX.Element, roles : string[]) => {
        if(props.user && roles.filter(x => props.user.roles.includes(x)).length > 0){
            return (
                <>
                    <ListItem key={text}>
                        <ListItemButton onClick={() => props.onClose(path)}>
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                    <Divider/>
                </>
            )
        }
    }

    return (
        <ClickAwayListener onClickAway={() => props.onClose}>
            <Drawer
                anchor={"left"}
                open={props.open}
                onClose={() => props.onClose()}
            >
                <Box
                    sx={{width: 300}}
                    role={"presentation"}
                >
                    {renderListItem("Home", "/", <HomeIcon/>, ["Teacher", "Admin"])}
                    {/*{renderListItem("Dashboard", "/dashboard", <DashboardIcon/>, ["Teacher", "Admin"])}*/}
                    {renderListItem("Arbeitsblatt", "/timetable", <TableChartIcon/>, ["Teacher", "Admin"])}
                    {renderListItem("Arbeitsblatt Admin", "/merge", <TableChartIcon/>, ["Admin"])}
                </Box>
            </Drawer>
        </ClickAwayListener>
    );
}

export default Navigation;