import React, {useEffect, useState} from "react";
import {useIsAuthenticated} from "@azure/msal-react";
import {Box, Grid} from "@mui/material";
import {To, useNavigate} from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import * as ClientTeacherActions from '../../client/user';
import Loading from '../../components/Loading/Loading'
import {User} from "../../interfaces/User/User";
import {useDispatch} from "react-redux";
import {login} from "../../features/userSlice";
import {toast} from "react-toastify";


const iconSize = {
    fontSize: 50
}

const boxStyle = {
    width: '100',
    height: '350px',
    backgroundColor: 'secondary',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;',
    display: 'flex',
    flexDirection: 'column',
    alignContent : 'center',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
}

const Home : React.FC = () => {

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    const dispatch = useDispatch();

    const [user, setUser] = useState<User>();
    const [isLoading, setIsLoading] = useState(false);

    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const goToPage = (path : To) => {
        navigate(path)
    }

    const getCurrentTeacher = () => {
        setIsLoading(true)
        ClientTeacherActions.getCurrentTeacher()
            .then((response) => {
                setUser(response.data)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error("Sie sind leider nicht in der Datenbank eingetragen. Bitte kontaktieren Sie den Administrator.")
            })
    }

    const renderNavigator = () => {
        return(
            <Grid item xs={12} md={3}>
                <Box sx={boxStyle} onClick={() => goToPage("/timetable")}>
                    <AccessTimeIcon sx={iconSize} />
                    <h1>Arbeitsblatt</h1>
                </Box>
            </Grid>
        )
    }

    const renderNavigatorAdmin = () => {
        if(isAdmin){
            return(
                <Grid item xs={12} md={3}>
                    <Box sx={boxStyle} onClick={() => goToPage("/merge")}>
                        <AccessTimeIcon sx={iconSize} />
                        <h1>Arbeitsblatt Admin</h1>
                    </Box>
                </Grid>
            )
        }
    }

    const renderSalutation = (user : User) => {
        if(user.gender == 2){
            return "Frau"
        }
        else {
            return "Herr"
        }
    }

    useEffect(() =>  {
        if(user != null){
            setIsLoading(false);
            dispatch(login(user))
            if(user.roles.includes("Admin")){
                setIsAdmin(true)
            }
        }
    },[user])

    useEffect(() => {
        if(isAuthenticated){
            getCurrentTeacher();
        }
    },[isAuthenticated])


    if(!isAuthenticated && !isLoading){
        return (
            <>
                <h1>Herzlich Willkommen im Intranet von SKDZ</h1>
                <p>Bitte loggen Sie sich ein.</p>
            </>
        )
    }
    else if(isLoading && isAuthenticated){
        return (
            <Loading isLoading={isLoading} />
        )
    }
    else {
        return (
            <>
                <h1>Willkommen {user && renderSalutation(user) + " " + user.firstname + " " + user.lastname}</h1>
                <Grid container spacing={2}>
                    {/*<Grid item xs={12} md={3}>
                        <Box sx={boxStyle} onClick={() => goToPage("/dashboard")}>
                            <DashboardIcon sx={iconSize}/>
                            <h1>Dashboard</h1>
                        </Box>
                    </Grid>*/}
                    {renderNavigator()}
                    {isAdmin && renderNavigatorAdmin()}
                </Grid>
            </>
        );
    }
}
export default Home;
