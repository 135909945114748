import React from "react";
import {
    Autocomplete,
    Box,
    Button, Fab,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip
} from "@mui/material";
import {isActive, isUploaded, TimetableFilter} from "../../interfaces/Filter/TimetableFilter";
import {defaultTeacherOption, TeacherOption} from "../../interfaces/Timetable/TeacherOption";
import moment from "moment";
import {TimetableEvent} from "../../interfaces/ResponseTypes/TimetableEvent";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {FilterTypes} from "../../interfaces/Filter/FilterTypes";


interface Props {
    teachers : TeacherOption[],
    executions : string[]
    filter : TimetableFilter,
    setFilter : React.Dispatch<React.SetStateAction<TimetableFilter>>,
    isAdmin : boolean,
    uploadFile? : (id : number[], e : React.ChangeEvent<HTMLInputElement>) => void,
    selectedEvents? : TimetableEvent[],
    children? : React.ReactNode,
    filterTypes : FilterTypes[]
}


const TimetableFilterForm : React.FC<Props> = (props: Props) => {

    const handleChangeSelect = (prop: keyof TimetableFilter, event: SelectChangeEvent<isUploaded | isActive>) => {
        var result = event.target.value;
        props.setFilter({...props.filter, [prop] : result});
    };


    const handleChangeAutocompleteTeacherOption = (prop: keyof TimetableFilter, newValue : TeacherOption | null) => {
        props.setFilter({...props.filter, teacher: newValue ? newValue : defaultTeacherOption});
    }

    const handleChangeAutocompleteExecution = (prop: keyof TimetableFilter, newValue : string | null) => {
        props.setFilter({...props.filter, execution: newValue ? newValue : ""});
    }

    const handleChangeValue = (event: SelectChangeEvent, prop : keyof TimetableFilter) => {
        props.setFilter({...props.filter, [prop] : event.target.value as string})
    }

    const displayFilter = (type : FilterTypes) : string => {
        if(props.filterTypes.includes(type)){
            return "block"
        }
        return "none"
    }

    return (
        <>
            {
                props.isAdmin && (
                    <>
                        <Grid display={displayFilter(FilterTypes.Teacher)} item>
                            <InputLabel id="teacher-selection">Lehrperson</InputLabel>
                            <Autocomplete
                                noOptionsText={"keine Lehrperson gefunden"}
                                getOptionLabel={(option: TeacherOption) => option.name}
                                value={props.filter.teacher}
                                id={"teacher-selection"}
                                options={props.teachers}
                                sx={{width: 300}}
                                renderInput={(params) => <TextField {...params} />}
                                onChange={(event: any, newValue: TeacherOption | null) => {
                                    handleChangeAutocompleteTeacherOption("teacher", newValue)
                                }}
                            />
                        </Grid>
                        <Grid display={displayFilter(FilterTypes.Execution)} item>
                            <InputLabel id="execution-selection">Ausbildung / Kurs</InputLabel>
                            <Autocomplete
                                noOptionsText={"keine Durchführung"}
                                value={props.filter.execution}
                                getOptionLabel={(option: string) => option}
                                id={"teacher-selection"}
                                options={props.executions}
                                sx={{width: 300}}
                                renderInput={(params) => <TextField {...params} />}
                                onChange={(event: any, newValue: string | null) => {
                                    handleChangeAutocompleteExecution("execution", newValue)
                                }}
                            />
                        </Grid>
                        <Grid display={displayFilter(FilterTypes.TeacherStatus)} item>
                            <InputLabel id={"active-teacher"}>Lehrerstatus</InputLabel>
                            <Select
                                id={"active-teacher"}
                                labelId={"active-teacher"}
                                value={props.filter.isActive}
                                sx={{width: 200}}
                                onChange={(event: SelectChangeEvent<isActive>) => handleChangeSelect("isActive", event)}
                            >
                                <MenuItem value={isActive.All}>Alle</MenuItem>
                                <MenuItem value={isActive.Active}>Aktiv</MenuItem>
                                <MenuItem value={isActive.InActive}>Nicht Aktiv</MenuItem>
                            </Select>
                        </Grid>
                        <Grid display={displayFilter(FilterTypes.isUploaded)} item>
                            <InputLabel id="uploaded-check">Status hochladen</InputLabel>
                            <Select
                                labelId={"uploaded-check"}
                                id={"uploaded-check"}
                                value={props.filter.isUploaded}
                                sx={{width: 200}}
                                label={"Arbeitsblatt hochgeladen"}
                                onChange={(event: SelectChangeEvent<isUploaded>) => handleChangeSelect("isUploaded", event)}
                            >
                                <MenuItem value={isUploaded.All}>Alle</MenuItem>
                                <MenuItem value={isUploaded.Uploaded}>Hochgeladen</MenuItem>
                                <MenuItem value={isUploaded.NotUploaded}>Nicht hochgeladen</MenuItem>
                            </Select>
                        </Grid>
                    </>
                )
            }
            <Grid display={displayFilter(FilterTypes.Year)} item>
                <Box>
                    <InputLabel id="year">Jahr</InputLabel>
                    <Select
                        value={props.filter.year.toString()}
                        id={"year"}
                        labelId={"year"}
                        label={"Jahr"}
                        onChange={(event) => handleChangeValue(event, "year")}
                    >
                        <MenuItem value={moment().year() - 2}>{moment().year() - 2}</MenuItem>
                        <MenuItem value={moment().year() - 1}>{moment().year() - 1}</MenuItem>
                        <MenuItem value={moment().year()}>{moment().year()}</MenuItem>
                        <MenuItem value={moment().year() + 1}>{moment().year() + 1}</MenuItem>
                    </Select>
                </Box>
            </Grid>
            <Grid display={displayFilter(FilterTypes.Month)} item xs={12} md={1}>
                <Box>
                    <InputLabel id="month">Monat</InputLabel>
                    <Select
                        value={props.filter.month.toString()}
                        id={"month"}
                        labelId={"month"}
                        label={"Monat"}
                        onChange={(event) => handleChangeValue(event, "month")}
                    >
                        <MenuItem value={0}>Alle</MenuItem>
                        <MenuItem value={1}>Januar</MenuItem>
                        <MenuItem value={2}>Februar</MenuItem>
                        <MenuItem value={3}>März</MenuItem>
                        <MenuItem value={4}>April</MenuItem>
                        <MenuItem value={5}>Mai</MenuItem>
                        <MenuItem value={6}>Juni</MenuItem>
                        <MenuItem value={7}>Juli</MenuItem>
                        <MenuItem value={8}>August</MenuItem>
                        <MenuItem value={9}>September</MenuItem>
                        <MenuItem value={10}>Oktober</MenuItem>
                        <MenuItem value={11}>November</MenuItem>
                        <MenuItem value={12}>Dezember</MenuItem>
                    </Select>
                </Box>
            </Grid>
            {props.children}

        </>
    );
}

export default TimetableFilterForm;