import * as React from "react";
import { Route } from "react-router";
import {Navigate, RouteProps} from "react-router-dom";
import Loading from "./Loading/Loading";
import Home from "../containers/Home/Home";
import {useIsAuthenticated} from "@azure/msal-react";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {selectUser} from "../features/userSlice";
import {Roles} from '../util/roles'
import { useEffect } from "react";
import {User} from "../interfaces/User/User";


interface Props extends RouteProps {
    user : User,
    roles : string[]
}

const ProtectedRoute: React.FC<Props> = (props: Props) => {
    const authed = useIsAuthenticated();

    useEffect(() => {
        if(props.user && !(props.user.roles.filter(x => props.roles.includes(x)).length > 0)){
            toast.error("Sie haben keine Berechtigung auf diese Seite zuzugreifen.")
        }
    },[props.user])

    if(!authed || !props.user || !(props.user.roles.filter(x => props.roles.includes(x)).length > 0)){
        return (
            <Navigate to={"/"} replace />
        )
    }
    return <>{props.children}</>;
};

export default ProtectedRoute;