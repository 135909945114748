import axios from "axios";
import { toast } from 'react-toastify';

export const configAxios = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.interceptors.request.use(function (config) {

        const idToken = sessionStorage.getItem("idToken");

        if(idToken){
            config.headers.Authorization = `Bearer ${idToken}`;
        }

        return config;
    });

    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response && (error.response.status === 400)) {
                // custom BadRequest messages that guides the user into the direction (return BadRequest("message"))
                toast.error(`${error.response.data}`);
            } else if (error.response.status === 401) {
                sessionStorage.clear();
                window.location.reload();
                //location.reload(); // reload to force re-authentication
            } else {
                toast.error(`${error.message}`);
            }

            return Promise.reject(error);
        }
    );
};
