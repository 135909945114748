import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./containers/Home/Home";
import React from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./containers/Dashboard/Dashboard";
import {useIsAuthenticated} from "@azure/msal-react";
import {Timetable} from "./containers/Timetable/Timetable";
import {useSelector} from "react-redux";
import {selectUser} from "./features/userSlice";
import {Merging} from "./containers/Merging/Merging";

const Routing : React.FC = () => {

    const user = useSelector(selectUser);

    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}></Route>
                <Route path="/dashboard" element={
                    <ProtectedRoute user={user} roles={["Teacher", "Admin"]}>
                        <Dashboard/>
                    </ProtectedRoute>
                } />
                <Route path="/timetable" element={
                    <ProtectedRoute user={user} roles={["Admin", "Teacher"]}>
                        <Timetable/>
                    </ProtectedRoute>
                } />
                <Route path="/merge" element={
                    <ProtectedRoute user={user} roles={["Admin"]}>
                        <Merging/>
                    </ProtectedRoute>
                } />
            </Routes>
        </>
    )
}

export default Routing;