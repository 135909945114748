import React, {useEffect, useState} from "react";
import * as ClientTeacherActions from '../../client/user';
import * as ClientTimetableActions from '../../client/timetable';
import {useSelector} from "react-redux";
import {selectUser} from "../../features/userSlice";
import Loading from "../../components/Loading/Loading";

const Dashboard : React.FC = () => {

    const [accessToken, setAccessToken] = useState<string>("");

    const [itemsPerPage, setItemsPerPage] = useState<number>(15);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [loading, setLoading] = useState<boolean>(false);

    const user = useSelector(selectUser);

    return(
        <>
            <Loading isLoading={loading} />
            <h1>Dashboard</h1>
        </>
    )
}

export default Dashboard;