import React, {useEffect} from "react";
import {AppBar, Box, Button, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useIsAuthenticated} from "@azure/msal-react";
import {SignInButton} from "../../components/LoginButtons/SignInButton";
import {SignOutButton} from "../../components/LoginButtons/SignOutButton";


interface Props {
    handleOpenNavigation : () => void
}

const Header : React.FC<Props> = (props: Props) => {

    const authenticated = useIsAuthenticated();

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position={"static"} sx={{ backgroundColor: '#a75cc8'}}>
                <Toolbar>
                    <IconButton
                        size={"large"}
                        edge={"start"}
                        disabled={!authenticated}
                        color={"inherit"}
                        aria-label={"menu"}
                        sx={{mr: 2}}
                        onClick={props.handleOpenNavigation}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        SKDZ Intranet
                    </Typography>
                    {!authenticated ? <SignInButton/> : <SignOutButton/>}
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default Header;