import React, {useEffect, useState} from "react";
import {TimetableEvent} from "../../interfaces/ResponseTypes/TimetableEvent";
import moment from 'moment';
import {Box, Button, Grid, IconButton, Input, Tooltip} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from "./ConfirmDelete";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    open : boolean,
    onClose : () => void,
    timetableEvent : TimetableEvent,
    uploadFile : (id: number[], e : React.ChangeEvent<HTMLInputElement>) => void
    deleteFile : (id : number) => void,
    downloadFile : (id : TimetableEvent) => void,

}

const EventDetail : React.FC<Props> = (props : Props) => {

    const [open, setOpen] = useState<boolean>(false);

    const handleCloseConfirmation = (confirm : boolean) => {
        if(confirm){
            props.deleteFile(props.timetableEvent.timetableEventId)
        }
        setOpen(false)
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                className={"eventModal-outline"}
                sx={{
                    outline: 'none'
                }}
            >
                <Box sx={style}>
                    <Box sx={{
                        position: 'absolute',
                        top : '15px',
                        right : '15px'
                    }}>
                        <IconButton onClick={props.onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid xs={12} item>
                            <h1>Ausbildung / Kurs - {props.timetableEvent.executionName ? props.timetableEvent.executionName : ""}</h1>
                        </Grid>
                        <Grid xs={6} item>
                            <span>Von: <b>{moment(props.timetableEvent.dateFrom).format("dddd, MMMM Do YYYY, h:mm:ss")}</b></span>
                        </Grid>
                        <Grid xs={6} item>
                            <span>Bis: <b>{moment(props.timetableEvent.dateTo).format("dddd, MMMM Do YYYY, h:mm:ss")}</b></span>
                        </Grid>
                        <Grid xs={12} item>
                        <span>{!props.timetableEvent.djoozeTimetableEventSubjects ? "" : props.timetableEvent.djoozeTimetableEventSubjects.length == 1 ? "Fach" : "Fächer"}: <b>{props.timetableEvent.djoozeTimetableEventSubjects.map((subject) => (
                            subject.name + " "
                        ))}</b></span>
                        </Grid>
                    </Grid>


                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    {
                        (!props.timetableEvent.timetableEventFile) ? (
                                <Box mt={5}>
                                    <label htmlFor="uploadFile">
                                        <Input
                                            id={"uploadFile"}
                                            type={"file"}
                                            style={{display: 'none'}}
                                            inputProps={{accept: '.pdf'}}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.uploadFile([props.timetableEvent.timetableEventId], e)}
                                        />
                                        <Button
                                            variant={"contained"}
                                            color={"secondary"}
                                            component={"span"}
                                            sx={{float: "left"}}
                                        >
                                            Aufgabenblatt hochladen
                                        </Button>
                                    </label>
                                </Box>
                            ) :
                            <>
                                <Box mt={5}>
                                    <Grid container spacing={2}>
                                        <Grid xs={12} item>
                                            <span>Dateiname : {props.timetableEvent.timetableEventFile.filename}</span>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant={"contained"}
                                                color={"primary"}
                                                component={"span"}
                                                sx={{float: "left"}}
                                                onClick={() => props.downloadFile(props.timetableEvent)}
                                            >
                                                Aufgabenblatt herunterladen
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="Bearbeiten">
                                                <IconButton onClick={() => setOpen(true)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <ConfirmDelete open={open}
                                                   onClose={(confirm: boolean) => handleCloseConfirmation(confirm)}/>
                                </Box>
                            </>
                    }

                </Box>
            </Modal>
        </>
    );
}

export default EventDetail;