import React, {useEffect, useState} from 'react';
import Routing from './routes';
import Header from "./containers/Header/Header";
import Navigation from './components/Navigation/Navigation';
import {BrowserRouter, Router, useNavigate} from "react-router-dom";
import {Box} from "@mui/material";
import {configAxios} from "./util/axios";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {logout, selectUser} from "./features/userSlice";

function App() {

    const [openNavigation, setOpenNavigation] = useState(false);
    const isAuthenticated = useIsAuthenticated();
    const user = useSelector(selectUser);

    const { instance } = useMsal();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
       /*if(!isAuthenticated){
           dispatch(logout());
       }*/
    },[])

    const handleCloseNavigation = (path? : string) => {
       setOpenNavigation(false)
        if(path != null){
           navigate(path)
       }
    }
    const handleOpenNavigation = () => {
        setOpenNavigation(true)
    }

    return (
    <>
        <Header handleOpenNavigation={handleOpenNavigation} />
        <Navigation open={openNavigation} onClose={handleCloseNavigation} user={user} />
        <Box p={5}>
            <Routing/>
        </Box>
    </>
    );
}

export default App;
