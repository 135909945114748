import React, {useEffect, useState} from "react";
import {defaultTimetableEvent, TimetableEvent} from '../../interfaces/ResponseTypes/TimetableEvent'
import {defaultTimetableFilter, TimetableFilter} from "../../interfaces/Filter/TimetableFilter";
import {TeacherOption} from "../../interfaces/Timetable/TeacherOption";
import * as ClientTeacherActions from "../../client/teacher";
import {toast} from "react-toastify";
import * as ClientTimetableActions from "../../client/timetable";
import Loading from "../../components/Loading/Loading";
import {
    Button,
    Checkbox, Fab, Fade,
    Grid, IconButton, Input,
    Link,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip
} from "@mui/material";
import TimetableFilterForm from "../../components/TimetableFilter/TimetableFilterForm";
import moment from "moment";
import {FilterTypes} from "../../interfaces/Filter/FilterTypes";
import MergeIcon from '@mui/icons-material/Merge';

export const Merging : React.FC = () => {

    const [loading, setLoading] = useState(true);
    const [timetableEvents, setTimetableEvents] = useState<TimetableEvent[]>();
    const [teachers, setTeachers] = useState<TeacherOption[]>([]);
    const [executionTypes, setExecutionTypes] = useState<string[]>([]);

    const [selectedEvent, setSelectedEvent] = useState<TimetableEvent>(defaultTimetableEvent);
    const [selectedEvents, setSelectedEvents] = useState<TimetableEvent[]>([])

    const [filter, setFilter] = useState<TimetableFilter>(defaultTimetableFilter);
    const [totalCount, setTotalCount] = useState(1);


    useEffect(() => {
       setFilter({...filter, onlyUploaded : true})
    },[])

    useEffect(() => {
        if(filter.onlyUploaded){
            fetchAllTimetables()
            fetchAllTeachers()
            getAllExecutionTypes()
        }
    },[filter])

    useEffect(() => {
        if(filter !== defaultTimetableFilter) setFilter({...filter, currentPage : 1})
    },[filter.year, filter.month, filter.teacher, filter.isUploaded])

    const fetchAllTeachers = () => {
        // @ts-ignore
        if(teachers.length !== []){
            ClientTeacherActions.getAllTeachers(filter)
                .then(r => {

                    setTeachers(r.data)
                })
                .catch(() => {

                    toast.error("Ein Fehler ist aufgetreten. Bitte kontaktieren sie den Administrator.")
                })
        }
    }

    const fetchAllTimetables = () => {
        setLoading(true)
        ClientTimetableActions.getAllTimetables(filter)
            .then((r) => {
                setTimetableEvents(r.data.data)
                setTotalCount(r.data.totalCount)
                setLoading(false)
            })
    }

    const downloadFile = (event : TimetableEvent) => {

        ClientTimetableActions.downloadFile(event.timetableEventId)
            .then(() => {

            })
            .catch(() => {
                toast.error("Ein Fehler ist aufgetreten. Bitte kontaktieren sie den Administrator.")

            })
    }

    const getAllExecutionTypes = () => {

        ClientTimetableActions.getAllExecutionTypes()
            .then((response) => {

                setExecutionTypes(response.data)
            })
            .catch(() => {
                toast.error("Ein Fehler ist aufgetreten. Bitte kontaktieren sie den Administrator.")

            });
    }

    const mergeFiles = (id: number[]) => {
        setLoading(true)
        ClientTimetableActions.mergeFiles(id)
            .then((r) => {
                setLoading(false)
                toast.success("Die Arbeitsblätter wurden zusammengeführt.")
            })
            .catch((err) => {
                setLoading(false)
                toast.error("Ein Fehler ist aufgetreten. Bitte kontaktieren sie den Administrator.")
            })
    }


    const addSelectedEvent = (event : React.ChangeEvent<HTMLInputElement>, timetableEvent : TimetableEvent) => {
        if(event.target.checked){
            setSelectedEvents([...selectedEvents, timetableEvent])
        }
        else {
            setSelectedEvents(selectedEvents.filter(item => item.timetableEventId !== timetableEvent.timetableEventId))
        }
    }

    const renderTeacherNames = (event : TimetableEvent) => {
        let names : string[] = [];
        if(event.djoozeTimetableEventTeachers){
            event.djoozeTimetableEventTeachers.forEach((teacher) => {
                names.push(teacher.name)
            })
        }
        return names.join(', ')
    }


    return (
        <>
            <Loading isLoading={loading} />
            <Grid container spacing={5} alignItems="center" mb={3}>
                <Grid item>
                    <h1>Arbeitsblatt Admin</h1>
                </Grid>
                <TimetableFilterForm teachers={teachers} filter={filter} setFilter={setFilter} isAdmin={true}
                                     filterTypes={[
                                         FilterTypes.Month,
                                         FilterTypes.Year,
                                         FilterTypes.Teacher,
                                         FilterTypes.TeacherStatus,
                                         FilterTypes.Execution
                                     ]}
                                     executions={executionTypes}
                >

                    <Fade in={selectedEvents && selectedEvents.length > 1}>
                        <Grid item position={"absolute"} bottom={15} right={15}>
                            <label htmlFor="uploadFiles">
                                <Input
                                    id={"uploadFiles"}
                                    style={{display: 'none'}}

                                />
                                <Fab
                                    component={"span"}
                                    sx={{float: "left"}}
                                    color={'primary'}
                                    size={"large"}
                                >
                                    <Tooltip open={selectedEvents && selectedEvents.length > 1} title={"Arbeitsblatt zusammenführen"}>
                                        <IconButton color={"secondary"} onClick={() => mergeFiles(selectedEvents?.map(x => x.timetableEventFile.id))}>
                                            <MergeIcon fontSize={"large"}/>
                                        </IconButton>
                                    </Tooltip>
                                </Fab>
                            </label>
                        </Grid>
                    </Fade>


                </TimetableFilterForm>
            </Grid>

            <TableContainer component={Paper}>
                {
                    timetableEvents && (
                        <Table sx={{minWidth: 500}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Auswahl</TableCell>
                                    <TableCell>Ausbildung / Kurs</TableCell>
                                    <TableCell>Fach</TableCell>
                                    <TableCell>Klasse</TableCell>
                                    <TableCell>Lehrperson</TableCell>
                                    <TableCell>Von</TableCell>
                                    <TableCell>Bis</TableCell>
                                    <TableCell>Datei</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    timetableEvents.map((event) => (
                                        <TableRow
                                            key={event.timetableEventId}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedEvents.includes(event) || selectedEvents.filter(e => e.timetableEventFile.id == event.timetableEventFile.id).length > 0}
                                                    onChange={(changeEvent : React.ChangeEvent<HTMLInputElement>) => addSelectedEvent(changeEvent, event)}
                                                />
                                            </TableCell>
                                            <TableCell>{event.executionName}</TableCell>
                                            <TableCell>{event.djoozeTimetableEventSubjects.map((subject) => subject.name + " ")}</TableCell>
                                            <TableCell>{event.className}</TableCell>
                                            <TableCell>{event.djoozeTimetableEventTeachers ? renderTeacherNames(event) : "kein Lehrer"}</TableCell>
                                            <TableCell>{moment(event.dateFrom).locale("de").format("L, HH:mm")}</TableCell>
                                            <TableCell>{moment(event.dateTo).locale("de").format("L, HH:mm")}</TableCell>
                                            <TableCell>{event.timetableEventFile != null ? (<Link sx={{ cursor: 'pointer' }} onClick={() => downloadFile(event)}>{event.timetableEventFile.filename}</Link>) : "-"}</TableCell>

                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    )
                }
            </TableContainer>
            <Grid container spacing={2} mt={5}>
                <Grid item xs={12} mt={3}>
                    <Pagination count={Math.ceil(totalCount / filter.itemsPerPage)} page={filter.currentPage} onChange={(event: React.ChangeEvent<unknown>, page: number) => setFilter({...filter, currentPage : page})} />
                </Grid>
            </Grid>
        </>
    )
}
