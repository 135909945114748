import { createTheme } from '@mui/material/styles';

export const theme = createTheme(({
    palette: {
        mode: 'light',
        primary: {
            main: '#21313D',
        },
        secondary: {
            main: '#FFFFFF',
        },
        error: {
            main: '#c22a40',
        },
    },
    typography: {
        h1: {
            fontSize: 32,
            fontWeight: 700,
        },
        h2: {
            fontSize: 28,
            fontWeight: 700,
        },
        h3: {
            fontSize: 24,
            fontWeight: 700,
        },
        h4: {
            fontSize: 22,
            fontWeight: 500,
        },
        h5: {
            fontSize: 20,
            fontWeight: 400,
        },
        h6: {
            fontSize: 18,
            fontWeight: 400,
        },
    }
}));