import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";
import {Button} from "@mui/material";
import {UserAgentApplication} from "msal";

export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest)
        .catch(e => {

        });

    }
    return (
        <Button color={"secondary"} onClick={() => handleLogin()}>Login</Button>
    )
}