import axios from "axios"
// @ts-ignore
import { saveAs } from "file-saver";
import {TimetableFilter} from "../interfaces/Filter/TimetableFilter";

export const getTimetableByMonth = (filter : TimetableFilter) => {
    return axios.post(`/api/timetable/getTimetable`, filter)
}

export const getAllTimetables = (filter : TimetableFilter, uploaded : boolean = false) => {
    return axios.post(`/api/timetable/getAllTimetables`, filter)
}

export const getAllExecutionTypes = () => {
    return axios.get(`/api/timetable/getAllExecutionTypes`);
}

export const uploadFile = (id : number[], file : File) => {
    const fd = new FormData();
    fd.append("timetableId", JSON.stringify(id));
    fd.append("file", file)
    let headers = {
        'Content-Type' : 'multipart/form-data'
    }
    return axios.post(`api/timetable/uploadFile`, fd, {
        headers
    })
}

export const mergeFiles = (ids : number[]) => {
    const fd = new FormData();
    fd.append("pdfIds", JSON.stringify(ids));
    let headers = {
        'Content-Type' : 'multipart/form-data',
        responseType : "blob"
    }
    return axios.post(`api/timetable/mergeFiles`, fd, {
        responseType : "blob",
        headers
    }).then(r => {
        var contentDisposition = r.headers["content-disposition"];
        var filename = contentDisposition.split('filename=')[1].split(';')[0];
        saveAs(r.data, filename.replace('"', '').replace('"', ''))
    })
}

export const deleteFile = (id : number) => {
    return axios.get(`api/timetable/deleteFile?timetableId=${id}`)
}

export const downloadFile = (id : number) => {
    return axios.get(`api/timetable/downloadFile?timetableId=${id}`, { responseType : "blob" })
        .then(r => {
        var contentDisposition = r.headers["content-disposition"];
        var filename = contentDisposition.split('filename=')[1].split(';')[0];
        saveAs(r.data, filename.replace('"', '').replace('"', ''))
    })
}