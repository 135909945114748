import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main.css'
import App from './App';
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./util/authConfig";
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import {configAxios} from "./util/axios";
import store from "./features/store";
import moment from "moment";
import 'moment/locale/de'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const msalInstance = new PublicClientApplication(msalConfig);
moment.locale("de");


msalInstance.handleRedirectPromise()
    .then((response) => {
        if(response != null){
            toast.success("Sie sind eingeloggt.");
            sessionStorage.setItem("idToken", response.idToken);
        }
    })
    .catch((error) => {
        toast.error("An error has occured. Please try again later.")
    })

configAxios()


root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ToastContainer 
        position='top-right'
        autoClose={3000}
      />
      <MsalProvider instance={msalInstance}>
          <BrowserRouter>
              <App/>
          </BrowserRouter>
      </MsalProvider>
    </ThemeProvider>
  </Provider>,
);