import {defaultTimetableEventSubject, TimetableEventSubject} from "./TimetableEventSubject";
import {defaultTimetableEventTeacher, TimetableEventTeacher} from "./TimetableEventTeacher";

export interface TimetableEvent{
    [key : string] : any,
    timetableName : string,
    timetableEventId : number,
    className : string,
    dateFrom : Date,
    executionName: string,
    timetableEventFile : any,
    dateTo : Date
    djoozeId : string,
    djoozeTimetableEventSubjects : TimetableEventSubject[]
    djoozeTimetableEventTeachers : TimetableEventTeacher[]
}

export const defaultTimetableEvent : TimetableEvent = {
    timetableName: "",
    timetableEventId : 0,
    className: "",
    executionName : "",
    timetableEventFile : null,
    dateFrom: new Date(),
    dateTo: new Date(),
    djoozeId : "",
    djoozeTimetableEventSubjects : [defaultTimetableEventSubject],
    djoozeTimetableEventTeachers : [defaultTimetableEventTeacher]
}



