import React from "react";
import {Backdrop, CircularProgress} from "@mui/material";

interface Props {
    isLoading : boolean
}

const Loading : React.FC<Props> = (props: Props) => {
    const style = {
        color: '#fff',
        zIndex : (theme : any) => theme.zIndex.drawer + 400
    }

    return (
        <Backdrop sx={style} open={props.isLoading} >
            <CircularProgress color={"inherit"}/>
        </Backdrop>
    )
}

export default Loading;