export interface TimetableEventSubject {
    id : number,
    name : string
}

interface Subject {
    subjectId : number,
    color : string,
    djoozeId : number,
    isActive: boolean,
    isFinalExam : boolean,
    name : string,
    shortName : string,
}

const defaultSubject : Subject = {
    subjectId : 0,
    color: "",
    djoozeId : 0,
    isActive : false,
    isFinalExam : false,
    name: "",
    shortName : "",
}
export const defaultTimetableEventSubject : TimetableEventSubject = {
    id : 0,
    name : ""
}




