export interface TimetableEventTeacher{
    id : number,
    name : string
}

interface Teacher {
    teacherId : number,
    name : string
    email : string
}

const defaultTeacher : Teacher = {
    teacherId : 0,
    name : "",
    email : ""
}

export const defaultTimetableEventTeacher : TimetableEventTeacher = {
    id : 0,
    name : ""
}
