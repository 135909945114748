import React from "react";
import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";

interface Props {
    open: boolean,
    onClose : (confirm : boolean) => void
}

const ConfirmDelete : React.FC<Props> = (props: Props) => {
    return (
        <>
            <Dialog
                open={props.open}
                onClose={() => props.onClose(false)}
            >
                <DialogTitle>Wollen Sie wirklich die Datei entfernen?</DialogTitle>
                <DialogActions>
                    <Button onClick={() => props.onClose(false)}>
                        Nein
                    </Button>
                    <Button onClick={() => props.onClose(true)} autoFocus>
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ConfirmDelete;