import {defaultTeacherOption, TeacherOption} from "../Timetable/TeacherOption";
import moment from 'moment';

export enum isUploaded {
    All,
    Uploaded,
    NotUploaded
}

export enum isActive {
    All,
    InActive,
    Active
}


export interface TimetableFilter {
    month : number,
    year : number,
    teacher : TeacherOption,
    execution : string
    currentPage: number,
    itemsPerPage : number,
    isUploaded : isUploaded,
    isActive : isActive,
    onlyUploaded : boolean
}

export const defaultTimetableFilter : TimetableFilter = {
    month : moment().month() + 1,
    year: moment().year(),
    teacher : defaultTeacherOption,
    execution : "",
    currentPage : 1,
    itemsPerPage : 15,
    isUploaded : isUploaded.All,
    isActive : isActive.Active,
    onlyUploaded : false
}
