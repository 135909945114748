import React from "react";
import { useMsal } from "@azure/msal-react";
import {Button} from "@mui/material";

export const SignOutButton = () => {
    const { instance, accounts } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect({
            account : instance.getAccountByHomeId(accounts[0].homeAccountId),
            postLogoutRedirectUri: "/",
        });
    }
    return (
        <Button color={"secondary"} onClick={() => handleLogout()}>Logout</Button>
    )
}