import React, {useEffect, useState} from "react";
import Loading from "../../components/Loading/Loading";
import moment from 'moment';
import * as ClientTimetableActions from '../../client/timetable';
import * as ClientTeacherActions from '../../client/teacher';
// @ts-ignore
import {saveAs} from 'file-saver';
import {defaultTimetableEvent, TimetableEvent} from '../../interfaces/ResponseTypes/TimetableEvent'
import {
    Checkbox, Fab,
    Fade,
    Grid,
    IconButton, Input,
    Link,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import EventDetail from "../../components/Timetable/EventDetail";
import {toast} from "react-toastify";
import 'moment/locale/de';
import {useSelector} from "react-redux";
import {selectUser} from "../../features/userSlice";
import {defaultTimetableFilter, TimetableFilter} from "../../interfaces/Filter/TimetableFilter";
import TimetableFilterForm from "../../components/TimetableFilter/TimetableFilterForm";
import {TeacherOption} from "../../interfaces/Timetable/TeacherOption";
import {FilterTypes} from "../../interfaces/Filter/FilterTypes";
import FileUploadIcon from "@mui/icons-material/FileUpload";


export const Timetable : React.FC = () => {

    const [loading, setLoading] = useState(false);
    const [timeTableEvents, setTimetableEvents] = useState<TimetableEvent[]>();
    const [openEvent, setOpenEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<TimetableEvent>(defaultTimetableEvent);
    const [selectedEvents, setSelectedEvents] = useState<TimetableEvent[]>([]);

    const [filter, setFilter] = useState<TimetableFilter>(defaultTimetableFilter);
    const [totalCount, setTotalCount] = useState(1);

    // Admin variables
    const user = useSelector(selectUser);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [teachers, setTeachers] = useState<TeacherOption[]>([]);


    useEffect(() => {
        moment.locale("de");
        setSelectedEvent(defaultTimetableEvent)
        setSelectedEvents([])
        if(user !== null){
            setIsAdmin(user.roles.includes("Admin"))
        }
    },[])

    useEffect(() => {
        if(user.roles.includes("Admin")){
            fetchAllTimetables()
            fetchAllTeachers()
        }
        else {
            fetchTimetables()
        }
    },[filter])

    const fetchAllTeachers = () => {
        // @ts-ignore
        if(teachers.length !== []){
            ClientTeacherActions.getAllTeachers(filter)
                .then(r => {
                    setTeachers(r.data)
                })
                .catch(() => {
                    toast.error("Ein Fehler ist aufgetreten. Bitte kontaktieren sie den Administrator.")
                })
        }

    }

    useEffect(() => {
        if(filter !== defaultTimetableFilter) setFilter({...filter, currentPage : 1})
    },[filter.year, filter.month, filter.teacher, filter.isUploaded])


    const addSelectedEvent = (event : React.ChangeEvent<HTMLInputElement>, timetableEvent : TimetableEvent) => {
        if(event.target.checked){
            setSelectedEvents([...selectedEvents, timetableEvent])
        }
        else {
            setSelectedEvents(selectedEvents.filter(item => item.timetableEventId !== timetableEvent.timetableEventId))
        }
    }

    const fetchTimetables = () => {
        setLoading(true)
        closeModal()
        ClientTimetableActions.getTimetableByMonth(filter)
            .then((r) => {
                setTimetableEvents(r.data.data)
                setLoading(false)
                setTotalCount(r.data.totalCount)
            })
    }

    const fetchAllTimetables = () => {
        setLoading(true)
        closeModal()
        ClientTimetableActions.getAllTimetables(filter)
            .then((r) => {
                setTimetableEvents(r.data.data)
                setLoading(false)
                setTotalCount(r.data.totalCount)
            })
    }


    const uploadFile = (id: number[], e : React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const files = Array.from(e.target.files)
        setLoading(true);
        ClientTimetableActions.uploadFile(id, files[0])
            .then((r) => {
                setLoading(false)
                toast.success("Das Arbeitsblatt wurde hochgeladen.")
                isAdmin ? fetchAllTimetables() : fetchTimetables()

            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const deleteFile = (id : number) => {
        setLoading(true)
        ClientTimetableActions.deleteFile(id)
            .then((r) => {
                setLoading(false)
                toast.success("Die Datei wurde gelöscht.")
                isAdmin ? fetchAllTimetables() : fetchTimetables()
            })
            .catch((error) => {
                setLoading(false)
                toast.error("Ein Fehler ist aufgetreten.")
            })
    }

    const downloadFile = (event : TimetableEvent) => {
        setLoading(true)
        ClientTimetableActions.downloadFile(event.timetableEventId)
            .then(() => {
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }


    const closeModal = () => {
        setOpenEvent(false)
        setSelectedEvent(defaultTimetableEvent)
    }

    const openModal = (event : TimetableEvent) => {
        setSelectedEvent(event)
    }

    useEffect(() => {
        if(selectedEvent !== defaultTimetableEvent){
            setOpenEvent(true)
        }
    },[selectedEvent])


    const returnValueWithColor = (isUploaded : boolean) => {
        if(isUploaded){
            return <span style={{color: 'green'}}>Ja</span>
        }
        else {
            return <span style={{color: 'red'}}>Nein</span>
        }
    }

    const renderTeacherNames = (event : TimetableEvent) => {
        let names : string[] = [];
        if(event.djoozeTimetableEventTeachers){
            event.djoozeTimetableEventTeachers.forEach((teacher) => {
                names.push(teacher.name)
            })
        }
        return names.join(', ')
    }

    const checkCheckbox = (event : TimetableEvent) : boolean | undefined => {
        if(event.timetableEventFile !== null || (selectedEvents.length > 0 && !(selectedEvents.filter(e => e.executionName == event.executionName).length > 0))){
            return true;
        }
        return false;
        //return (event.timetableEventFile != null) || (selectedEvents.filter(e => e.)
    }



    return (
        <>
            <Loading isLoading={loading}/>
            <Grid container spacing={5} alignItems="center" mb={3}>
                <Grid item>
                    <h1>Arbeitsblatt</h1>
                </Grid>
                <TimetableFilterForm
                    teachers={teachers}
                    filter={filter}
                    setFilter={setFilter}
                    isAdmin={isAdmin}
                    uploadFile={uploadFile}
                    selectedEvents={selectedEvents}
                    filterTypes={[
                        FilterTypes.Month,
                        FilterTypes.Year,
                        FilterTypes.isUploaded,
                        FilterTypes.Teacher,
                        FilterTypes.TeacherStatus,
                    ]}
                    executions={[]}
                >
                    <Fade in={selectedEvents && selectedEvents.length > 0}>
                        <Grid item bottom={15} right={15}>
                            <label htmlFor="uploadFiles">
                                <Input
                                    id={"uploadFiles"}
                                    type={"file"}
                                    style={{display: 'none'}}
                                    inputProps={{accept: '.pdf'}}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => uploadFile(selectedEvents?.map(x => x.timetableEventId), e)}
                                />
                                <Fab
                                    component={"span"}
                                    sx={{float: "left"}}
                                    color={'primary'}
                                    size={"large"}
                                >
                                    <Tooltip open={selectedEvents && selectedEvents.length > 0} title={"Arbeitsblatt hochladen"}>
                                        <FileUploadIcon fontSize={"large"}/>
                                    </Tooltip>
                                </Fab>
                            </label>
                        </Grid>
                    </Fade>


                </TimetableFilterForm>
            </Grid>

            <TableContainer component={Paper}>
                {
                    timeTableEvents && (
                        <Table sx={{minWidth: 500}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Auswahl</TableCell>
                                    <TableCell>Ausbildung / Kurs</TableCell>
                                    <TableCell>Fach</TableCell>
                                    <TableCell>Klasse</TableCell>
                                    {isAdmin && (
                                        <TableCell>Lehrperson</TableCell>
                                    )}
                                    <TableCell>Von</TableCell>
                                    <TableCell>Bis</TableCell>
                                    <TableCell>Arbeitsblatt hochgeladen</TableCell>
                                    <TableCell>Datei</TableCell>
                                    <TableCell>Bearbeiten</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    timeTableEvents.map((event) => (
                                        <TableRow
                                            key={event.timetableEventId}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell>
                                                <Checkbox
                                                    disabled={checkCheckbox(event)}
                                                    checked={selectedEvents.includes(event)}
                                                    onChange={(changeEvent : React.ChangeEvent<HTMLInputElement>) => addSelectedEvent(changeEvent, event)}
                                                />
                                            </TableCell>
                                            <TableCell>{event.executionName}</TableCell>
                                            <TableCell>{event.djoozeTimetableEventSubjects.map((subject) => subject.name + " ")}</TableCell>
                                            <TableCell>{event.className}</TableCell>
                                            {isAdmin && (
                                                <TableCell>{event.djoozeTimetableEventTeachers ? renderTeacherNames(event) : "kein Lehrer"}</TableCell>
                                            )}
                                            <TableCell>{moment(event.dateFrom).locale("de").format("L, HH:mm")}</TableCell>
                                            <TableCell>{moment(event["dateTo"]).locale("de").format("L, HH:mm")}</TableCell>
                                            <TableCell>{returnValueWithColor(event.timetableEventFile !== null)}</TableCell>
                                            <TableCell>{event.timetableEventFile != null ? (<Link sx={{ cursor: 'pointer' }} onClick={() => downloadFile(event)}>{event.timetableEventFile.filename}</Link>) : "-"}</TableCell>
                                            <TableCell>
                                                <Tooltip title="Bearbeiten">
                                                    <IconButton onClick={() => openModal(event)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    )
                }
            </TableContainer>

            <EventDetail open={openEvent} onClose={closeModal} timetableEvent={selectedEvent} uploadFile={uploadFile} downloadFile={downloadFile} deleteFile={deleteFile} />

            <Grid container spacing={2} mt={5}>

                <Grid item xs={12} mt={3}>
                    <Pagination count={Math.ceil(totalCount / filter.itemsPerPage)} page={filter.currentPage} onChange={(event: React.ChangeEvent<unknown>, page: number) => setFilter({...filter, currentPage : page})} />
                </Grid>
            </Grid>
        </>
    );
}